import PropTypes from "prop-types";
import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { Link } from "gatsby";

import Header from "./header";

function Layout({ children }) {
  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900 bg-red-200 font-semibold">
      <Header />

      <main className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full">
        {children}
      </main>

      <footer className="bg-red-400">
        <nav className="flex justify-between max-w-4xl mx-auto p-4 md:p-8 text-xs items-center">
          <p className="text-white">
            Created by{` `}
            <OutboundLink
              className="font-bold no-underline text-white"
              href="https://modelsbymike3d.com"
            >
              Michael Porter
            </OutboundLink>
          </p>

          <div>
            <p className="text-white text-right">
              © {new Date().getFullYear()}
            </p>
            <p className="text-white text-right">
              <Link to="/privacy">Privacy Policy</Link>
            </p>
          </div>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
